import { useState } from 'react';
import { reduxForm, SubmissionError } from 'redux-form';

import { user } from 'services';
import { addToGreyPlate, getClientsDict, updateGreyPlate } from 'services/payments-service';
import { Button, themes, Popup2, ParkingSession } from 'libs/ui';
import { ParkingFilter2, required, WhiteStyledField, Title, ErrorMsg, DictInput } from 'libs/form';
import { StyledButtonContainer } from './greyPlate-form.styled';

const GreyPlateForm = (props) => {
  const { error, destroy, refresh, setMessage, form, handleSubmit, close, type, clientId, id, meanId, meanType } = props;

  const rightSessionActive = user.haveRight('grey_plate.show_active_stay');
  const isAdd = type === 'addForm';

  /**
   * after submit
   */
  const afterSubmit = () => {
    refresh();
    setMessage('Изменения сохранены');
    setTimeout(() => {
      setMessage('');
    }, 3000);
    close();
    destroy(form);
  };

  /**
   * submit
   * @param {*} values
   * @returns
   */
  const submit = (values) => {
    // data
    const test = false;
    const output = { ...values };
    const { vehicle_plate, comment, reason } = output;
    const out = { vehicle_plate, comment, reason };

    // out.mean_type = 'grey_plate';

    // if (!out.client_id && clientId) {
    //   out.client_id = clientId;
    // }
    // if (!clientId && !out.client_id) {
    //   out.client_id = null;
    //   out.client_name = null;
    // }

    // dates
    // if (out.start_at && typeof out.start_at !== 'string') {
    //   out.start_at = out.start_at.format();
    // }
    // if (out.end_at && typeof out.end_at !== 'string') {
    //   out.end_at = out.end_at.format();
    // }

    // if (!isAdd) {
    //   out.id = id;
    //   // confirm
    //   if (out.mean_id !== props.initialValues.mean_id) {
    //     setData(out);
    //     setConfirm(true);
    //     return false;
    //   }
    // }

    if (test) {
      // console.log('🚀 ~ submit ~ out:', out);
      return Promise.resolve();
    }

    // method
    const method = isAdd ? addToGreyPlate : updateGreyPlate;
    return method(out)
      .then(() => {
        afterSubmit();
        props.refresh();
        return true;
      })
      .catch((errors) => {
        if (errors && errors.data) {
          throw new SubmissionError({
            _error: errors.data.type,
          });
        }
        if (errors && errors.field && errors.message) {
          const fields = {
            mean_id: 'Номер',
          };
          const fn = fields[errors.field] ?? errors.field;
          const out = {
            _error: fn + ' ' + errors.message.toLowerCase(),
            [errors.field]: errors.message,
          };
          // console.log('🚀 ~ submit ~ out:', out);
          throw new SubmissionError(out);
        }
      });
  };

  // const submitAfterConfirm = () => {
  //   return updateGreyPlate(data)
  //     .then(() => {
  //       afterSubmit();
  //     })
  //     .catch((errors) => {
  //       if (errors && errors.data) {
  //         throw new SubmissionError({
  //           _error: errors.data.type,
  //         });
  //       }
  //     });
  // };

  const cancel = () => {
    close();
    destroy(form);
  };

  const getClients = (name) => {
    return getClientsDict({
      filter_params: { name },
      sort_params: [{ field_name: 'name', direction: 'asc' }],
      paging_params: { limit: 5, offset: 0 },
    });
  };

  const title = isAdd ? 'Добавить ГРЗ в серый список' : 'Изменить клиента в сером списке';

  return (
    <Popup2 w={482} top={3}>
      <Title>{title}</Title>
      <form onSubmit={handleSubmit(submit)}>
        <ErrorMsg error={error} />
        <ParkingFilter2 />
        {!clientId && (
          <WhiteStyledField
            selectedName={props.client_name}
            label="Клиент"
            placeholder="Клиент"
            type="autocomplete2"
            name="client_id"
            getList={getClients}
            // validate={required}
          />
        )}
        <DictInput dict="payment_mean.status" />

        <WhiteStyledField type="text" label="ГРЗ" placeholder="А000AA 000 rus" name="vehicle_plate" validate={required} />

        <WhiteStyledField type="text" label="Инициатор" placeholder="Инициатор" name="iniciator" />

        <WhiteStyledField type="text" label="Причина внесения" placeholder="Причина внесения" name="reason" validate={required} />

        <WhiteStyledField
          className="shortfield"
          type="dateTime"
          label="Дата действия с"
          placeholder="Дата действия с"
          name="created_at"
          // validate={required}
        />

        <WhiteStyledField
          className="shortfield"
          type="dateTime"
          label="Дата действия по"
          placeholder="Дата действия по"
          name="end_at"
          // validate={required}
        />
        <WhiteStyledField className="shortfield" type="text" name="comment" label="Комментарий" placeholder="Комментарий" validate={required} />
        {!isAdd && rightSessionActive && <ParkingSession meanType={meanType} meanId={meanId} />}
        <StyledButtonContainer space="20px">
          <Button type="button" onClick={cancel}>
            Отменить
          </Button>
          <Button theme={themes.blue} type="submit">
            <i className="fa fa-save" />
            Сохранить
          </Button>
        </StyledButtonContainer>
      </form>
    </Popup2>
  );
};

export default reduxForm({
  form: 'GreyPlateForm',
  destroyOnUnmount: false,
})(GreyPlateForm);
