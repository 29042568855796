import { useState, useEffect } from 'react';

import { api } from 'api';
import zones from 'services/zones';
import { notify } from 'libs/common';
import { Button, themes } from 'libs/ui';

import { Buttons, Content, Zone, ZoneIcon, ZoneInput, ZoneTitle } from './ZonesLK.styled';
import { ToggleBox, Toggle } from '../styledComponents';

export const ZonesLK = (props) => {
  const { clientId } = props;
  const [ready, setReady] = useState(false);
  const [limits, setLimits] = useState([]);

  useEffect(() => {
    const getData = () => {
      api.call('clients.get_zones', { client_id: clientId }).then((data) => {
        //console.log('🚀 ~ api ~ data', data);
        const colors = zones.getZonesColors();
        const limits = data.map((el) => {
          return { ...el, color: colors[el.zone_id] };
        });

        setReady(true);
        setLimits(limits);
      });
    };
    getData();
  }, []);

  /**
   * change value
   * @param {*} idx
   */
  const change = (idx) => {
    const out = limits.splice(0);
    let val = !out[idx].is_zone_visible;
    out[idx].is_zone_visible = val;
    setLimits(out);
  };

  /**
   * get zone inputs
   * @returns
   */
  const getZonesEl = () => {
    return limits.map((el, idx) => {
      return (
        <Zone key={idx}>
          <ZoneIcon color={el.color} />
          <ZoneTitle>{el.zone_id}</ZoneTitle>
          <ZoneInput>
            <ToggleBox isActive={el.is_zone_visible}>
              <p>Доступна</p>
              <Toggle isActive={el.is_zone_visible} onClick={() => change(idx)}>
                <div></div>
              </Toggle>
              <p>Нет</p>
            </ToggleBox>
          </ZoneInput>
        </Zone>
      );
    });
  };

  /**
   * save
   */
  const save = () => {
    const out = limits.map((el) => {
      return { zone_id: el.zone_id, is_zone_visible: el.is_zone_visible };
    });
    //console.log('🚀 ~ out', out);
    api.call('clients.set_zones', { client_id: clientId, zones: out }).then((data) => {
      notify('Зоны ЛК', 'Зоны ЛК сохранены', 'success');
    });
  };

  if (!ready) {
    return null;
  }

  const zonesEl = getZonesEl();

  return (
    <Content>
      {zonesEl}
      <Buttons>
        <Button theme={themes.gray} onClick={save}>
          <i className="fa fa-save" />
          Сохранить
        </Button>
      </Buttons>
    </Content>
  );
};
