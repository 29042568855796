import { useState } from 'react';

import { prepareFilter } from 'libs/common';
import { getVehicleClasses, getCategories } from 'services/types';
import { deleteFromGreyPlate, getGreyPlate } from 'services/payments-service';
import { Controller } from 'libs/control';
import { GreyPlateGrid } from './grid';
import GreyPlateFilter from './filter';

export const GreyPlate = (props) => {
  const [items, setItems] = useState({});
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [sortParams, setSortParams] = useState([{ field_name: 'id', direction: 'desc' }]);

  // classes
  const vehicleClasses = getVehicleClasses();
  const categories = getCategories();

  /**
   * prepare params
   * @param {*} data
   * @returns
   */
  const prepareParams = (data) => {
    const intFields = ['client_id', 'start_date', 'end_date'];
    const boolFields = ['is_blocked'];
    const addParams = {};
    if (props.clientId) {
      addParams.client_id = props.clientId;
    }
    return prepareFilter(data, addParams, { boolFields, intFields });
  };

  // grid actions
  const gridActions = {
    addEntries: (data) => setItems({ ...items, ...data }),
    clearEntries: () => setItems({}),
    updateEntry: (id, data) => {
      const newItems = { ...items };
      newItems[id] = { ...newItems[id], ...data };
      setItems(newItems);
    },
    setSortParams: (data) => setSortParams(data),
    setOffset: (val) => setOffset(val),
    setCount: (val) => setCount(val),
  };

  // grid params
  const gridParams = {
    entries: items,
    offset,
    count,
    sortParams,
  };

  return (
    <Controller
      {...props}
      {...gridParams}
      {...gridActions}
      type="grey_plate"
      filterName="GreyPlateFilter"
      grid={GreyPlateGrid}
      gridFilter={GreyPlateFilter}
      getRequestParams={prepareParams}
      // additional data
      tariffs={getGreyPlate}
      vehicleClasses={vehicleClasses}
      categories={categories}
    />
  );
};
