import { data } from 'services';
import { getCategoryById, getVehicleClassById } from 'services/types';
import { ParkingCell } from 'libs/ui';
import { render } from 'react-dom/cjs/react-dom.development';

export const getTableParams = () => {
  const fields = [
    { name: 'vehicle_plate', title: 'ГРЗ', width: 130, sortField: 'vehicle_plate' },
    {
      name: 'status',
      title: 'Статус',
      width: 145,
      sortField: 'status',
      render: (val) => 'Здесь будет статус из BE' + data.getDictValue('grey_plate.status', val),
    },
    { name: 'created_at', title: 'Дата действия с', width: 210, sortField: 'created_at', render: (val) => new Date(val).toLocaleString() },
    { name: 'updated_at', title: 'Дата изменения', width: 210, sortField: 'updated_at', render: (val) => new Date(val).toLocaleString() },
    { name: 'client_name', title: 'Клиент', width: 147, sortField: 'client_name' },
    { name: 'vehicle_class', title: 'Класс ТС', width: 112, sortField: 'vehicle_class', render: (val) => getVehicleClassById(val) },
    { name: 'reason', title: 'Причина внесения', width: 112, sortField: 'reason' },
    { name: 'tariff', title: 'Тариф', width: 180 },
    { name: 'comment', title: 'Комментарий', width: 150 },
  ];

  if (window.config.central) {
    fields.unshift({ name: 'parking_id', title: 'Парковка', render: (val) => <ParkingCell parking_id={val} cmp={'span'} />, width: 140 });
  }

  // get row class
  const getRowClass = (row) => {
    let out = '';
    out += ' ' + row.status;
    return out;
  };

  return { fields, getRowClass, withComment: true };
};
