import styled from 'styled-components';

export const Content = styled.div`
  position: relative;
  color: #fff;
`;

export const ZoneIcon = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  line-height: 32px;
  border: 2px solid #fff;
  font-size: 13px;
  color: white;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
`;
export const Zone = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
`;
export const ZoneTitle = styled.div`
  margin: 0 10px 0 10px;
  min-width: 70px;
`;
export const ZoneInput = styled.div`
  display: flex;
  align-items: center;
  > div {
    position: static;
  }
`;
export const Buttons = styled.div`
  margin: 30px 0 0;
`;
